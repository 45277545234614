<template>
  <div class="conference" ref="conference">
    <div class="nav-bar">
      <p>
        <van-icon name="arrow-left" size="18" color="#fff" />
      </p>
      <p>
        <img src="@/assets/underway.png" alt="" />
        <span>12:37</span>
      </p>
      <p>
        <span>结束课程</span>
      </p>
    </div>
    <div class="content">
      <ul v-if="type == 1 || type == 2">
        <li v-for="val in 5" :key="val">
          <img
            src="@/assets/banner01.png"
            alt=""
            class="head-portrait"
            v-if="type == 1"
          />
          <div class="video-con" v-if="type == 2">
            <img
              src="@/assets/banner01.png"
              alt=""
              class="video-img"
              v-if="val == 2"
            />
            <!-- 该标签应该为video，直播中画面，img暂时用来样式展示 -->
            <img
              src="@/assets/banner02.png"
              alt=""
              class="video-underway"
              v-else
            />
          </div>
          <p class="member-p">
            <span>姓名{{ val }}</span>
            <img src="@/assets/icon/sound.png" alt="" />
            <!-- 静音 -->
            <!-- <img src="@/assets/icon/sound-close.png" alt="" /> -->
            <!-- 讲话中 -->
            <!-- <img src="@/assets/icon/sound.png" alt="" /> -->
          </p>
        </li>
      </ul>
      <div
        class="select-member"
        :style="{ height: selectMemberHeight }"
        v-if="type == 3 || type == 4"
      >
        <img
          src="@/assets/banner01.png"
          alt=""
          class="select-member-video"
          v-if="type == 3"
        />
        <div class="screen" v-if="type == 4">
          <img src="@/assets/banner01.png" alt="" />
        </div>
        <img
          src="@/assets/video-pack-up.png"
          alt=""
          class="select-member-pack-up"
          v-if="type == 3"
        />
        <div class="addressor">
          <div class="video-con">
            <img src="@/assets/banner01.png" alt="" class="video-img" />
            <!-- 该标签应该为video，直播中画面，img暂时用来样式展示 -->
            <!-- <img
              src="@/assets/banner02.png"
              alt=""
              class="video-underway"
              v-else
            /> -->
          </div>
          <p class="member-p">
            <span>姓名</span>
            <img src="@/assets/icon/sound.png" alt="" />
            <!-- 静音 -->
            <!-- <img src="@/assets/icon/sound-close.png" alt="" /> -->
            <!-- 讲话中 -->
            <!-- <img src="@/assets/icon/sound.png" alt="" /> -->
          </p>
        </div>
        <div class="select-member-info" v-if="type == 3">
          <img src="@/assets/banner01.png" alt="" />
          <span>李云</span>
          <img src="@/assets/icon/sound.png" alt="" />
        </div>
        <div class="screen-member-info" v-if="type == 4">
          <img src="@/assets/banner01.png" alt="" />
          <span>李云分享的屏幕</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>
          <img src="@/assets/icon/sound.png" alt="" />
          <p>静音</p>
        </li>
        <li>
          <img src="@/assets/icon/camera-close.png" alt="" />
          <!-- <img src="@/assets/icon/camera.png" alt="" /> -->
          <p>摄像头</p>
        </li>
        <li>
          <img src="@/assets/icon/invite.png" alt="" />
          <p>邀请</p>
        </li>
        <li>
          <img src="@/assets/icon/member.png" alt="" />
          <p>成员</p>
        </li>
        <li>
          <img src="@/assets/icon/more.png" alt="" />
          <p>更多</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      type: 3,
      selectMemberDivHeight: 0,
    };
  },
  computed: {
    selectMemberHeight() {
      return this.selectMemberDivHeight + "px";
    },
  },
  mounted() {
    this.selectMemberDivHeight = this.$refs.conference.offsetHeight - 103;
  },
  updated() {
    this.selectMemberDivHeight = this.$refs.conference.offsetHeight - 103;
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.conference {
  min-height: 100vh;
  background: #404245;
  p {
    margin: 0;
  }
  .nav-bar {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #24272d;
    padding: 0 12px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    > p {
      width: 33.3%;
    }
    > p:nth-child(2) {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 15px;
      > img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
    > p:last-child {
      color: #f33;

      text-align: right;
      font-size: 14px;
    }
  }
  .footer {
    width: 100%;
    height: 55px;
    background: #24272d;
    position: fixed;
    left: 0;
    bottom: 0;
    > ul {
      width: 100%;
      height: 55px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        width: 20%;
        text-align: center;
        padding: 8px 0;
        box-sizing: border-box;
        color: rgba(255, 255, 255, 0.76);
        font-size: 12px;
        > img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .content {
    width: 100%;
    min-height: 100vh;
    padding: 48px 0 55px 0;
    box-sizing: border-box;
    background: #404245;
    display: flex;
    align-items: center;
    > ul {
      width: 100%;
      min-height: 100%;
      background: #404245;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 0 8px 40px 8px;
      box-sizing: border-box;
      li {
        width: 25%;
        text-align: center;
        margin: 16px 0;
        color: #fff;
        font-size: 12px;
      }
    }
    .select-member {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .select-member-video {
        width: 100%;
        height: 100%;
      }

      .select-member-pack-up {
        position: absolute;
        left: 16px;
        top: 16px;
        width: 28px;
        height: 28px;
      }
      .addressor {
        position: absolute;
        right: 16px;
        top: 18px;
      }
      .select-member-info {
        position: absolute;
        left: 16px;
        bottom: 16px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 12px;
        > img:nth-child(1) {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        > span:nth-child(2) {
          margin: 0 2px 0 4px;
        }
        > img:nth-child(3) {
          width: 12px;
          height: 12px;
        }
      }
      .screen-member-info {
        position: absolute;
        left: 16px;
        bottom: 16px;
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 12px;
        > img:nth-child(1) {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
      .screen {
        width: 375px;
        height: 217px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .head-portrait {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  .member-p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    font-size: 12px;
    color: #fff;
    > img {
      width: 12px;
      height: 12px;
      margin-left: 2px;
    }
  }
  .video-con {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background: #24272d;
    text-align: center;
    .video-img {
      width: 48px;
      height: 48px;
      margin-top: 16px;
      border-radius: 50%;
    }
    .video-underway {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}
</style>
